<template>
    <div class="contain-box">
        <div class="contain-header">
        </div>
        <div class="contain-body">
            <div class="box-header">
            </div>
            <div class="box-body">
                <div class="ranking-title">
                    <span class="rank">Ranking</span>
                    <span class="gled">Gledge</span>
                </div>
                <div class="ranking-box">
                    <div class="ranking-item" v-for="(rank, index) in ranking" :key="index">

                        <div class="ranks one" v-if="rank.rank == 1">
                            {{rank.rank}}
                        </div>
                        <div class="ranks two" v-else-if="rank.rank == 2">
                            {{rank.rank}}
                        </div>
                        <div class="ranks three" v-else-if="rank.rank == 3">
                            {{rank.rank}}
                        </div>
                        <div class="ranks gerant" v-else>
                            {{rank.rank}}
                        </div>
                        <div class="rank-na">
                            <span>{{rank.name}}</span>
                        </div>
                        <div class="gled-num">
                            <span>{{rank.gledge}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
import "@/css/ranking.css"

export default {
    name: 'Ranking',
    data() {
        return {
            ranking: [{
                name: '0x23...5564',
                gledge: 123123123,
                rank: 1
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 2
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 3
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 4
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 5
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 6
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 7
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 8
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 9
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 10
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 11
            }, {
                name: '0x23...5564',
                gledge: 123123123,
                rank: 12
            }]
        }
    },

    created() {

    },

    methods: {





    }

}
</script>